import { Dictionary } from '@ngrx/entity';

import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { html_beautify } from 'js-beautify';
import merge from 'lodash/merge';
import Mustache from 'mustache/mustache.min';

import { SLayout } from '../state/reducers/layout.reducer';
import { STemplateModel } from '../state/reducers/template-model.reducer';

import { RecoItem } from '../../providers/recos.provider';

import { DEFAULT_HEADER } from '../../newsletters-module/declarations/template';
import { TemplateVariablesForMustache } from '../../newsletters-module/declarations/template-variables';
import { Article } from '../declarations/article';
import { BlockBlueprint, ContentBlueprint, LayoutBlueprint } from '../declarations/blueprints';
import { CompilationContext, CompilationResult } from '../declarations/compilation';
import {
  DEFAULT_CONTENT_INNER_STYLES,
  DEFAULT_CONTENT_OUTER_STYLES,
  DEFAULT_CONTENT_PARAMETERS,
  SUPPORTED_TEMPLATE_VERSION
} from '../declarations/constants';
import { compileConstraint, Constraint, DEFAULT_GLOBAL_CONSTRAINTS } from '../declarations/constraint';
import { Content, isContent } from '../declarations/content';
import {
  ButtonContent,
  compileToHtmlNodes as compileButtonToHtmlNodes,
  computeHoverRulesForButton
} from '../declarations/content/button.content';
import { CodeContent, compileToHtmlNodes as compileCodeToHtmlNodes } from '../declarations/content/code.content';
import { compileToHtmlNodes as compileImageToHtmlNodes, ImageContent } from '../declarations/content/image.content';
import {
  compileToHtmlNodes as compilePowerspaceToHtmlNodes,
  PowerspaceContent
} from '../declarations/content/powerspace.content';
import { compileToHtmlNodes as compileSpacerToHtmlNodes, SpacerContent } from '../declarations/content/spacer.content';
import { compileToHtmlNodes as compileTextToHtmlNodes, TextContent } from '../declarations/content/text.content';
import {
  compileTitleToHtmlNodes as compileTitleToHtmlNodes,
  TitleContent
} from '../declarations/content/title.content';
import { ConstraintPattern, ContentType, LayoutType } from '../declarations/enums';
import {
  compileLayoutToHtmlNodes,
  CustomBlockRef,
  DEFAULT_LAYOUT_RENDERING_PROPERTIES,
  isLayout,
  Layout
} from '../declarations/layout';
import {
  applyStyles,
  InnerRenderingProperties,
  RenderingProperties
} from '../declarations/rendering-properties';
import { ModelElement, ParentElement } from '../declarations/structure';
import { TemplateHeader, TemplateModel } from '../declarations/template-model';

import { rgbToHexString } from '../../mediego-common-module/utils/color.utils';

import { TemplatePowerspaceUtils } from './template-powerspace.utils';

Mustache.escape = function(value) {
  return value;
};

export class TemplateModelUtils {

  static modelVersionIsSupported(model: TemplateModel): boolean {
    const { release, structure, style } = model.version;
    return SUPPORTED_TEMPLATE_VERSION.release === release &&
      SUPPORTED_TEMPLATE_VERSION.structure === structure &&
      SUPPORTED_TEMPLATE_VERSION.style === style;
  }

  static getBackwardCompatibleTemplateModel(model: STemplateModel): STemplateModel {
    return {
      ...model,
      globalCss: model.globalCss || '',
      favoriteColors: model.favoriteColors || ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
      customFonts: model.customFonts || ['', ''],
      headers: model.headers || {
        preheader: { ...DEFAULT_HEADER }
      },
      constraints: model.constraints || [...DEFAULT_GLOBAL_CONSTRAINTS]
    };
  }

  static contentIdHash(content: Content): string {
    return content.id.split('-')[0];
  }

  static extractSLayoutsFromParentElement(parent: ParentElement): SLayout[] {
    return parent.children.reduce((layouts, child) => {
      if (isLayout(child)) {
        return [
          ...layouts,
          {
            ...child,
            children: child.children.map((__) => ({
              id: __.id,
              type: __.type
            }))
          },
          ...TemplateModelUtils.extractSLayoutsFromParentElement(child)
        ];
      } else {
        return layouts;
      }
    }, [] as Layout[])
  }

  static extractContentsFromLayout(layout: Layout): Content[] {
    return layout.children.reduce((contents, child) => {
      if (isContent(child)) {
        return [...contents, child];
      } else {
        return [
          ...contents,
          ...TemplateModelUtils.extractContentsFromLayout(child)
        ];
      }
    }, [] as Content[])
  }

  static extractContentIdFromSLayout(layout: SLayout, type: ContentType): string[] {
    return layout?.children?.reduce((contents, child) => {
      if (isContent(child)) {
        return child.type === type ? [...contents, child.id] : [...contents];
      } else {
        return [
          ...contents,
          ...TemplateModelUtils.extractContentIdFromSLayout(child as SLayout, type)
        ];
      }
    }, []) || [];
  }

  static parseParentElementArticleEntries = (parent: ParentElement): Article[] => {
    return Object.entries(
      _parseParentElementArticleEntries(parent)
    ).map(([key, value]) => {
      return {
        id: key,
        elements: value
      }
    });

    function _parseParentElementArticleEntries(parent: ParentElement, baseEntries: { [index: string]: string[] } = {}): { [index: string]: string[] } {
      let entryStore = baseEntries;

      parent.children.forEach((child: Layout | Content) => {
        if (child.type === ContentType.Empty) {
          return;
        }

        if (isLayout(child)) {
          entryStore = {
            ...entryStore,
            ..._parseParentElementArticleEntries(child, entryStore)
          }
        } else if (isContent(child)) {
          if (child.articleRef) {
            entryStore = {
              ...entryStore,
              [child.articleRef]: [...(entryStore[child.articleRef] || []), child.id]
            };
          } else {
            return;
          }
        } else {
          console.warn('child is neither layout nor content', child);
          return;
        }
      }, {});

      return entryStore;
    }
  };

  static parseBlockBlueprint(block: BlockBlueprint, i18n: TranslateService, parentRef?: string, articleIds: { [index: string]: string } = {}): Layout {
    const layoutId = TemplateModelUtils.create_UUID();

    if (block.articleIndex !== undefined) {
      articleIds[block.articleIndex] = articleIds[block.articleIndex] || TemplateModelUtils.create_UUID();
    }

    const children: Array<Layout | Content> = block.children
      .map((child: LayoutBlueprint | ContentBlueprint) => {
        if (isLayout(child)) {
          return TemplateModelUtils.parseBlockBlueprint(child as LayoutBlueprint, i18n, layoutId, articleIds);
        } else {
          return TemplateModelUtils.parseContentBlueprint(child as ContentBlueprint, layoutId, i18n, articleIds);
        }
      });

    return {
      id: layoutId,
      type: block.type,
      name: i18n.instant('TEMPLATER.VIEWPORT.BLOCK.' + block.type) || block.type,
      children,
      parentRef,
      renderingProperties: merge({
        width: block.width,
        height: block.height
      } as Partial<RenderingProperties>, DEFAULT_LAYOUT_RENDERING_PROPERTIES)
    };
  }

  static parseContentBlueprint(blueprint: ContentBlueprint, parentRef: string, i18n: TranslateService, articleIds: { [index: string]: string } = {}): Content {
    const {
      articleIndex, width, height, type, params,
      outerRenderingProperties, innerRenderingProperties
    } = blueprint;

    const name = i18n.instant('TEMPLATER.VIEWPORT.CONTENT.' + type + '.name') || blueprint.name;

    const obj = {
      id: TemplateModelUtils.create_UUID(),
      type, name, parentRef,
      size: { width, height },
      innerRenderingProperties: merge({}, DEFAULT_CONTENT_INNER_STYLES[type], innerRenderingProperties),
      outerRenderingProperties: merge({ width, height }, DEFAULT_CONTENT_OUTER_STYLES[type], outerRenderingProperties),
      params: merge({}, DEFAULT_CONTENT_PARAMETERS[type], params)
    };

    if (type === ContentType.Title) {
      obj.params.static.titleContent = i18n.instant('TEMPLATER.VIEWPORT.CONTENT.title.placeholder');
    } else if (type === ContentType.Text) {
      obj.params.static.textContent = i18n.instant('TEMPLATER.VIEWPORT.CONTENT.text.placeholder');
    } else if (type === ContentType.Button) {
      obj.params.static.buttonContent = i18n.instant('TEMPLATER.VIEWPORT.CONTENT.button.placeholder.static');
      obj.params.dynamic.buttonContent = i18n.instant('TEMPLATER.VIEWPORT.CONTENT.button.placeholder.dynamic');
    }

    if (articleIndex !== undefined) {
      articleIds[articleIndex] = articleIds[articleIndex] || TemplateModelUtils.create_UUID();

      return {
        ...obj,
        articleRef: articleIds[articleIndex]
      };
    } else {
      return {
        ...obj,
        articleRef: null
      };
    }
  }

  static parseCustomBlock(blockId: string, rows: Layout[], parentRef?: string, articleIds: { [index: string]: string } = {}): Layout[] {
    return rows.map((row: Layout) => {
      return TemplateModelUtils.parseCustomBlockRow(blockId, row, null, articleIds);
    });
  }

  private static parseCustomBlockRow(blockId: string, layout: Layout, parentRef?: string, articleIds: { [index: string]: string } = {}): Layout {
    const newLayoutId = TemplateModelUtils.create_UUID();

    const customBlockRef: CustomBlockRef = {
      id: blockId,
      childrenId: parentRef ? null : layout.id // see CustomBlockRef interface for further details
    };

    const children: Array<Layout | Content> = layout.children
      .map((child: Layout | Content) => {
        if (isLayout(child)) {
          return TemplateModelUtils.parseCustomBlockRow(blockId, child, newLayoutId, articleIds);
        } else {
          let articleRef = null;

          if (child.articleRef !== null) {
            articleIds[child.articleRef] = articleIds[child.articleRef] || TemplateModelUtils.create_UUID();
            articleRef = articleIds[child.articleRef];
          }

          return {
            ...child,
            id: TemplateModelUtils.create_UUID(),
            parentRef: newLayoutId,
            articleRef
          };
        }
      });

    return {
      ...layout,
      id: newLayoutId,
      parentRef,
      customBlockRef,
      children
    };
  }

  static selectSortedArticleIdsInModel(layouts: Dictionary<SLayout>, contents: Dictionary<Content>, model: STemplateModel): string[] {
    return [...new Set(
      model.children.reduce((allIds: string[], rowId) => {
        const idsInRow = TemplateModelUtils.selectSortedArticleIdsInLayout(layouts, contents, layouts[rowId]);
        return [
          ...allIds,
          ...idsInRow
        ]
      }, [])
    )];
  }

  static countArticleIdInModel(layouts: Dictionary<SLayout>, contents: Dictionary<Content>, model: STemplateModel, articleId: string): number {

    const articlesId: string[] = model.children.reduce((allIds: string[], rowId) => {
      const idsInRow = TemplateModelUtils.selectSortedArticleIdsInLayout(layouts, contents, layouts[rowId]);
      return [
        ...allIds,
        ...idsInRow
      ]
    }, []);

    if (articlesId && articlesId.length) {
      return articlesId.filter(article => article === articleId).length;
    } else {
      return 0;
    }
  }

  static selectSortedArticleIdsInLayout(layouts: Dictionary<SLayout>, contents: Dictionary<Content>, layout: SLayout): string[] {
    return [...new Set(
      layout.children.reduce((articleIds: string[], child: { id: string; type: LayoutType | ContentType }) => {
        if (isLayout(child)) {
          return [...articleIds, ...TemplateModelUtils.selectSortedArticleIdsInLayout(layouts, contents, layouts[child.id])];
        } else if (isContent(child)) {
          const fullChild: Content = contents[child.id];
          if (fullChild && fullChild.articleRef) {
            return [...articleIds, fullChild.articleRef];
          } else {
            return articleIds;
          }
        } else {
          console.warn('selectArticleIdsInLayout: layout has child that is neither layout nor content');
          return articleIds;
        }
      }, [])
    )];
  }


  // return row ids sorted by newsletter natural order (top row ids come first and bottom row ids come last)
  static sortRowIdsWithNaturalOrder(childrenIds: string[], rowIds: string[]): string[] {

    if (childrenIds && childrenIds.length) {
       const rowIdsSorted: string[] = rowIds.sort(
         (a, b) => (childrenIds.indexOf(a) < childrenIds.indexOf(b)) ? -1 : 1
       );

       return rowIdsSorted;
    } else {
      return rowIds;
    }
  }

  static create_UUID(): string {
    const s4 = () => Math
      .floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  static toString(model: TemplateModel): string {
    // functions are automatically omitted by stringify()
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#Description
    return JSON.stringify(model);
  }

  static compileQuillOutputToHtmlNodes(quillHTMLOutput: string, spaceBetweenParagraphs: number, disableEncoding: boolean): Node[] {
    const div_tag = document.createElement('div') as HTMLDivElement;
    div_tag.innerHTML = quillHTMLOutput;

    const renderedParagraphs: Node[] = [];

    div_tag.childNodes.forEach((p_tag: HTMLParagraphElement, index: number) => {
      // safety check solving article initialization problem
      if (p_tag && p_tag.style) {
        p_tag.style.margin = '0';
        p_tag.style.lineHeight = '1.2';

        // adding padding bottom except for last p
        if (typeof spaceBetweenParagraphs === 'number' && index < div_tag.childNodes.length - 1) {
          p_tag.style.paddingBottom = spaceBetweenParagraphs + 'px';
        }

        renderedParagraphs.push(p_tag);
      }
    });

    if (disableEncoding) {
      renderedParagraphs.forEach((p_tag: HTMLParagraphElement) => {
        // for each a in p_tag, set data-disable-tracking attribute
        p_tag.querySelectorAll('a').forEach((a_tag: HTMLAnchorElement) => {
          a_tag.setAttribute('data-disable-tracking', 'true');
        });
      });
    }

    return renderedParagraphs;
  }

  static compileContentToHtmlNodes(content: Content, context: CompilationContext): CompilationResult {

    const result: CompilationResult = {
      nodes: [],
      context
    };

    const articleIndex = context.articles?.findIndex((__) => __.id === content.articleRef);

    let compilation: CompilationResult;

    const spaceBetweenParagraphs = context.root?.renderingProperties?.spaceBetweenParagraphs;

    switch (content.type) {
      case ContentType.Image:
        compilation = compileImageToHtmlNodes(content as ImageContent, articleIndex, context);
        break;
      case ContentType.Text:
        compilation = compileTextToHtmlNodes(content as TextContent, articleIndex, spaceBetweenParagraphs, context);
        break;
      case ContentType.Title:
        compilation = compileTitleToHtmlNodes(content as TitleContent, articleIndex, spaceBetweenParagraphs, context);
        break;
      case ContentType.Button:
        compilation = compileButtonToHtmlNodes(content as ButtonContent, articleIndex, context);
        break;
      case ContentType.Code:
        compilation = compileCodeToHtmlNodes(content as CodeContent, articleIndex, context);
        break;
      case ContentType.Powerspace:
        let adPosition = context.adPositions?.indexOf(content?.id);
        if (typeof adPosition === 'number') adPosition++;
        compilation = compilePowerspaceToHtmlNodes(content as PowerspaceContent, articleIndex, adPosition, false, context);
        break;
      case ContentType.Spacer:
        compilation = compileSpacerToHtmlNodes(content as SpacerContent, context);
        break;
      default:
        throw Error(`compileContentToHtmlNodes(): unknown ContentType ${content.type}.`);
    }

    const contentTable = document.createElement('table') as HTMLTableElement;

    // only adding content-xxx if class is used inside head styles
    if (content.type === ContentType.Text) contentTable.classList.add('content-' + content.type);
    contentTable.style.width = '100%';
    contentTable.width = '100%';
    contentTable.style.borderCollapse = 'collapse';
    contentTable.cellPadding = '0';
    contentTable.cellSpacing = '0';
    contentTable.style.border = 'none';

    const tr_tag = document.createElement('tr') as HTMLTableRowElement;
    contentTable.appendChild(tr_tag);

    const td_tag = document.createElement('td') as HTMLTableCellElement;
    tr_tag.appendChild(td_tag);

    if (content.type === ContentType.Text || content.type === ContentType.Title) {
      td_tag.classList.add('ql');
    }

    applyStyles(td_tag, content.outerRenderingProperties);

    // selecting only relevant properties based on editorial override specifications
    if (content.editorialOverrides?.enabled) {
      const propertiesOverridenByEditorial = content.editorialOverrides.innerRenderingProperties.reduce(
        (properties, propertyName: keyof InnerRenderingProperties) => {
          properties[propertyName] = content.innerRenderingProperties[propertyName];
          return properties;
      }, {});
      applyStyles(td_tag, propertiesOverridenByEditorial);
    }

    td_tag.append(...compilation.nodes);

    result.nodes = [contentTable];
    return result;
  }

  static compileElementToHtmlNodes(element: ModelElement,
                                   context: CompilationContext): CompilationResult {

    if (context.parentSizes) context.parentSizes.push(element.size);

    if (isLayout(element)) {
      return compileLayoutToHtmlNodes(element, context);
    } else if (isContent(element)) {
      return TemplateModelUtils.compileContentToHtmlNodes(element, context);
    } else {
      const msg = 'setElementHighlighted(): element is neither Layout nor Content';
      console.warn(msg, element);
      throw Error(msg);
    }
  }

  static compileModelToMustacheTemplate(model: TemplateModel, articles: Article[], subject: TemplateHeader = null): string {

    const adPositions = TemplatePowerspaceUtils.extractAdPositionsFromModel(model) || [];

    const getSafeHeaderMustache = (header: TemplateHeader) => {

      if (!header.isTrusted) {
        // no valid mustache found -> default value
        return header.staticValueForBackup;
      } else {

        if (header.valueCompiled && header.recoIndexesNeeded.length) {
          // adding if-else logic with main & default value

          // TODO: consider all recos if mustache allows it (or else put a flag in scala indicating if compile OK)
          const firstReco: number = header.recoIndexesNeeded[0];

          const ifElseText: string =
            `{{ #reco_${firstReco} }}
                ${header.valueCompiled}
              {{ /reco_${firstReco} }}
              {{ ^reco_${firstReco} }}
                ${header.staticValueForBackup}
              {{ /reco_${firstReco} }}`;

          return ifElseText;

        } else if (header.valueCompiled) {
          // looks like preheader does not contain any reco
          // however it can contain other mustache variable in the following format : {{ variables.name }}
          const matches = /{{\s*variables\.([a-z0-9_]+)\s*}}/gi.exec(header.valueCompiled)

          if (matches && matches.length >= 2) {
            const variableName = matches[1];

            if (variableName) {
              return `{{ #variables.${variableName} }}
                ${header.valueCompiled}
              {{ /variables.${variableName} }}
              {{ ^variables.${variableName} }}
                ${header.staticValueForBackup}
              {{ /variables.${variableName} }}`;
            }
          }

          return header.valueCompiled;
        } else {
          // default value as no valueCompiled found
          return header.staticValueForBackup;
        }
      }
    };

    // Html
    const root = document.createElement('html') as HTMLHtmlElement;
    // root.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
    root.style.height = '100%';

    // Head
    const head = document.createElement('head');

    const outlookConfiguration = document.createComment('[if (gte mso 9)]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]');
    head.appendChild(outlookConfiguration);

    const charset = document.createElement('meta') as HTMLMetaElement;
    charset.httpEquiv = 'Content-Type';
    charset.content = 'text/html; charset=utf-8';
    head.appendChild(charset);

    const viewport = document.createElement('meta') as HTMLMetaElement;
    viewport.name = 'viewport';
    viewport.content = 'width=device-width';
    head.appendChild(viewport);

    const ieCompatibility = document.createElement('meta') as HTMLMetaElement;
    ieCompatibility.httpEquiv = 'X-UA-Compatible';
    ieCompatibility.content = 'IE=9; IE=8; IE=7; IE=EDGE';
    head.appendChild(ieCompatibility);

    // no subject => value will be provided by external service (API for example)
    const title = document.createElement('title');
    title.textContent = '{{ #subject }}{{ subject }}{{ /subject }}';

    head.appendChild(title);

    const powerspaceResponsiveStyles: string = adPositions.length ? `
        .hide, [class$="hide"] {
          width: 0 !important;
          height: 0 !important;
          display: none !important;
        }

        .show, [class$="show"] {
          overflow: visible !important;
          display: block !important;
          line-height: 100% !important;
          max-height: none !important;
        }
    ` : '';

    const yahooSpecificResponsiveStyle: string = adPositions.length ?
      `@media yahoo {
    .show { width: 0px !important; height: 0px !important; display: none !important; }
}` : '';

    const baseStyle = document.createElement('style');
    baseStyle.textContent = `
      table td { border-collapse: collapse; }
      table > tr { vertical-align: inherit; }

      @media only screen and (max-width: 480px) {
        #mediego-email-container-table {
          width:100% !important;
        }
        .mediego-layout-table, .mdg-lt {
          width:100% !important;
        }
        .mediego-layout-table.responsive .mediego-row-td, .mdg-lt.r .mdg-row {
          display:block !important;
          width:100% !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }

        .mediego-image-content.responsive, .mdg-img.r {
          width:100% !important;
        }
        ${powerspaceResponsiveStyles}
      }
      ${yahooSpecificResponsiveStyle}
      .ql {
        font-family: Helvetica, Arial, sans-serif;
        font-weight: normal;
        font-size: 13px;
      }
      .ql a {
        color: inherit;
        text-decoration: none;
      }
      .ql p {
        margin-block-start: 0;
        margin-block-end: 0;
      }
      .ql img {
        vertical-align: top;
        max-width: 100%;
      }
      .ql .ql-font-monospace {
        font-family: Monaco, Courier New, monospace;
      }
      .ql .ql-font-serif {
        font-family: Georgia, Times New Roman, serif;
      }
      th {
        font-weight: normal;
      }

      .content-text p:not(:last-child) {
        padding-bottom: ${model.renderingProperties.spaceBetweenParagraphs || 0}px;
      }
    `;

    head.appendChild(baseStyle);

    const globalStyles = document.createElement('style');
    globalStyles.textContent = model.globalCss;
    head.appendChild(globalStyles);

    root.appendChild(head);

    // Body
    const body = document.createElement('body');
    if (model.renderingProperties?.secondaryColor) {
      body.style.backgroundColor = rgbToHexString(model.renderingProperties.secondaryColor);
      body.style.margin = '0px';
      body.style.padding = '0px';
    }
    root.appendChild(body);

    if (model?.headers?.preheader) {
      const preheader = model.headers.preheader;
      const preheaderDiv = document.createElement('div');
      preheaderDiv.style.display = 'none';
      preheaderDiv.style.fontSize = '1px';
      preheaderDiv.style.opacity = '0';
      const preheaderSafe = getSafeHeaderMustache(preheader);
      preheaderDiv.appendChild(document.createTextNode(preheaderSafe));
      body.appendChild(preheaderDiv);
    }


    const bodyTable = document.createElement('table') as HTMLTableElement;
    body.appendChild(bodyTable);

    bodyTable.id = 'mediego-body-table';

    // bodyTable.border = '0';
    bodyTable.style.border = 'none';
    bodyTable.cellPadding = '0';
    bodyTable.cellSpacing = '0';
    bodyTable.width = '100%';
    bodyTable.style.width = '100%';
    bodyTable.style.height = '100%';
    bodyTable.style.borderCollapse = 'collapse';
    bodyTable.style.borderSpacing = '0';

    bodyTable.style.fontFamily = 'Helvetica, Arial, sans-serif';
    bodyTable.style.backgroundColor = rgbToHexString(model.renderingProperties.secondaryColor!);
    bodyTable.style['webkitTextSizeAdjust'] = '100%';
    bodyTable.style['msTextSizeAdjust'] = '100% !important';

    const bodyTr = document.createElement('tr') as HTMLTableRowElement;
    bodyTable.appendChild(bodyTr);

    const bodyTd = document.createElement('td') as HTMLTableCellElement;
    bodyTr.appendChild(bodyTd);
    bodyTd.align = 'center';
    bodyTd.vAlign = 'top';


    // Content
    const emailContainerTable = document.createElement('table') as HTMLTableElement;
    bodyTd.appendChild(emailContainerTable);

    emailContainerTable.id = 'mediego-email-container-table';

    // emailContainerTable.border = '0';
    emailContainerTable.style.border = 'none';
    emailContainerTable.cellPadding = '0';
    emailContainerTable.cellSpacing = '0';
    emailContainerTable.style.borderCollapse = 'collapse';
    emailContainerTable.style.borderSpacing = '0';

    applyStyles(emailContainerTable, model.renderingProperties);
    applyStyles(emailContainerTable, model.size);

    emailContainerTable.style.minWidth = ''; // shouldn't set this for emailContainerTable
    emailContainerTable.style.maxWidth = ''; // shouldn't set this for emailContainerTable
    // emailContainerTable.style.margin = '0 auto';
    // emailContainerTable.align = 'center';

    const emailContainerTr = document.createElement('tr') as HTMLTableRowElement;
    emailContainerTable.appendChild(emailContainerTr);

    const emailContainerTd = document.createElement('td') as HTMLTableCellElement;
    emailContainerTr.appendChild(emailContainerTd);
    emailContainerTd.align = 'center';
    emailContainerTd.vAlign = 'top';


    let compileFunctionForRootElements = TemplateModelUtils.compileElementToHtmlNodes;
    const compilationContext: CompilationContext = {
      darkmodeContents: [],
      articles,
      root: model,
      parentSizes: [],
      adPositions,
      constraints: model.constraints,
      hoverContents: []
    };

    const layoutFullOrNothingActiveConstraint = (constraint: Constraint): boolean =>
      (constraint?.active && constraint.pattern === ConstraintPattern.LayoutFullOrNothing);

    if (model?.constraints?.some(layoutFullOrNothingActiveConstraint)) {
      const constraint: Constraint = model.constraints.find(layoutFullOrNothingActiveConstraint);

      // need to enable specific compilation
      // proxy function
      compileFunctionForRootElements = (layout, context): CompilationResult => {
        return compileConstraint(constraint, layout as Layout, context);
      };

    }

    const compilation = compileFunctionForRootElements(model, compilationContext);
    for (const node of compilation.nodes) {
      emailContainerTd.appendChild(node);
    }

    if (compilationContext.darkmodeContents?.length) {
      console.warn('adding specific styles and meta tags for darkmode');
      console.warn(compilationContext.darkmodeContents);

      const darkmodeSupport = document.createElement('meta') as HTMLMetaElement;
      darkmodeSupport.name = 'color-scheme';
      darkmodeSupport.content = 'dark light';
      head.insertBefore(darkmodeSupport, baseStyle);

      const darkmodeOldSupport = document.createElement('meta') as HTMLMetaElement;
      darkmodeOldSupport.name = 'supported-color-schemes';
      darkmodeOldSupport.content = 'dark light';
      head.insertBefore(darkmodeOldSupport, baseStyle);

      const darkmodeRootStyles: string = `
          :root {
            color-scheme: dark light;
            supported-color-schemes: dark light;
          }
        `;

      const darkmodeMediaQueries: string = `
         @media (prefers-color-scheme: dark) {

          .mdg-light { display: none !important; }
          .mdg-dark { display: block !important; }

          h1, h2,h3, p, a {
            color: rgb(0, 0, 0);
          }

          .Singleton h1, .Singleton h2, .Singleton h3, .Singleton p, .Singleton a {
            -webkit-text-fill-color: rgb(255, 255, 255);
          }
        }

        [data-ogsb] h1, [data-ogsb] h2,[data-ogsb] h3, [data-ogsb] p, [data-ogsb] a {
          color: rgb(0, 0, 0);
          -webkit-text-fill-color: rgb(255, 255, 255);
        }

        [data-ogsc] .mdg-light { display: none; display: none !important; }
        [data-ogsc] .mdg-light { display: none; display: none !important; }
        [data-ogsb] .mdg-dark { display: block; display: block !important; }
        [data-ogsb] .mdg-dark { display: block; display: block !important; }`;

      baseStyle.textContent += darkmodeRootStyles;
      baseStyle.textContent += darkmodeMediaQueries;
    }

    if (compilationContext.hoverContents.length) {

      let cssRulesForHoverEffects = '';

      compilationContext.hoverContents.forEach(content => {
        switch (content.type) {
          case ContentType.Button:
            cssRulesForHoverEffects += computeHoverRulesForButton(content);
            break;
          default:
            break;
        }
      });

      if (cssRulesForHoverEffects) baseStyle.textContent += cssRulesForHoverEffects;
    }

    // Add of tracking pixel
    const trackingPixelBlock = document.createElement('div');
    trackingPixelBlock.appendChild(document.createTextNode('{{ #trackingPixel }}'));

    const trackingPixelImg = document.createElement('img');
    trackingPixelImg.src = '{{ trackingPixel }}';
    trackingPixelImg.width = 1;
    trackingPixelImg.height = 1;
    trackingPixelImg.title = '';
    trackingPixelImg.alt = '';

    trackingPixelBlock.appendChild(trackingPixelImg);
    trackingPixelBlock.appendChild(document.createTextNode('{{ /trackingPixel }}'));

    body.appendChild(trackingPixelBlock);


    const doctype = '<!DOCTYPE html>';


    // avoid &quot; inside font-family CSS rule
    const noHtmlEntity = /(?<=font-family:(?:[\s\w,-]|&quot;)*)&quot;(?=(?:[\s\w,-]|&quot;)*(?=[;]|["]))/gi

    return doctype + '\n' + html_beautify(root.outerHTML.replace(noHtmlEntity, '\''));
  }

  static compileCustomBlockModelToMustacheTemplate(model: Layout[]): string {
    const blockContent = document.createElement('div');

    const tempCustomBlockColumn: Layout = {
      id: TemplateModelUtils.create_UUID(),
      type: LayoutType.Column,
      name: 'Column',
      children: model,
      renderingProperties: DEFAULT_LAYOUT_RENDERING_PROPERTIES
    };

    const customBlockCompilation = TemplateModelUtils.compileElementToHtmlNodes(tempCustomBlockColumn, {
      parentSizes: [],
      adPositions: [],
      articles: [],
      darkmodeContents: [],
      hoverContents: []
    });
    for (const node of customBlockCompilation.nodes) {
      blockContent.appendChild(node);
    }

    return html_beautify(blockContent.innerHTML)
  }

  static getTemplateGlobalVariables(variables: TemplateVariablesForMustache, withTomorrow: boolean = false): TemplateVariablesForMustache {
    // Date variables
    const locale = 'fr'; // FIXME: locale should depend on client
    const now = dayjs().locale(locale);
    const nowPlusOneMonth = now.add(1, 'month');
    const tomorrow = now.add(1, 'day');

    const day = now.format('DD');
    const dayOfWeek = now.format('dddd').toLowerCase();
    const month = now.format('MM');
    const monthName = now.format('MMMM').toLowerCase();
    const nextMonth = nowPlusOneMonth.format('MM');
    const nextMonthName = nowPlusOneMonth.format('MMMM').toLowerCase();
    const year = now.format('YYYY');

    const isMonday = now.day() === 1;
    const isTuesday = now.day() === 2;
    const isWednesday = now.day() === 3;
    const isThursday = now.day() === 4;
    const isFriday = now.day() === 5;
    const isSaturday = now.day() === 6;
    const isSunday = now.day() === 7;
    const isWeekend = isSaturday || isSunday;

    const tomorrow_day = tomorrow.format('DD');
    const tomorrow_dayOfWeek = tomorrow.format('dddd').toLowerCase();
    const tomorrow_month = tomorrow.format('MM');
    const tomorrow_monthName = tomorrow.format('MMMM').toLowerCase();
    const tomorrow_year = tomorrow.format('YYYY');

    const mirrorLink = '[MIRROR] sera disponible lors de l\'envoi [MIRROR]';
    const unsubscribeLink = '[UNSUBSCRIBE] sera disponible lors de l\'envoi [UNSUBSCRIBE]';

    let dateVariables: Record<string, string|number> = {
      day,
      dayOfWeek,
      dayOfWeekCapital: dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1),
      isMonday: isMonday ? 'true' : '',
      isTuesday: isTuesday ? 'true' : '',
      isWednesday: isWednesday ? 'true' : '',
      isThursday: isThursday ? 'true' : '',
      isFriday: isFriday ? 'true' : '',
      isSaturday: isSaturday ? 'true' : '',
      isSunday: isSunday ? 'true' : '',
      isWeekend: isWeekend ? 'true' : '',
      month,
      monthName,
      monthNameCapital: monthName.charAt(0).toUpperCase() + monthName.slice(1),
      nextMonth,
      nextMonthName,
      nextMonthNameCapital: nextMonthName.charAt(0).toUpperCase() + nextMonthName.slice(1),
      year
    };

    if (withTomorrow) dateVariables = {
      ...dateVariables,
      tomorrow_day,
      tomorrow_dayOfWeek,
      tomorrow_dayOfWeekCapital: tomorrow_dayOfWeek.charAt(0).toUpperCase() + tomorrow_dayOfWeek.slice(1),
      tomorrow_month,
      tomorrow_monthName,
      tomorrow_monthNameCapital: tomorrow_monthName.charAt(0).toUpperCase() + tomorrow_monthName.slice(1),
      tomorrow_year
    }

    return {
      ...variables,
      ...dateVariables,
      mirrorLink,
      unsubscribeLink
    };
  }

  static getTemplateUserVariables(): TemplateVariablesForMustache {

    const email_md5 = '[MD5]';
    const email_sha256 = '[SHA256]';

    // TODO fetch variables configured by client when possible

    return {
      'user': {
        email_md5,
        email_sha256
      },
      'user.email_md5': email_md5,
      'user.email_sha256': email_sha256
    };
  }

  static renderMustacheText(text: string, variables: object): string {

    let textRendered: string = text;

    if (text) {

        const openingCurlyBraces: number = (text.match(/\{\{/g) || []).length;
        const closingCurlyBraces: number = (text.match(/\}\}/g) || []).length;

        if (openingCurlyBraces === closingCurlyBraces) {
          try {
            textRendered = Mustache.render(text, variables);
          } catch (err) {
            console.error(err);
            console.error('MUSTACHE ERROR: error on mustache compilation for', text);
            textRendered = text;
          }
        } else {
          console.error('MUSTACHE ERROR: text content contains a difference between opening curly braces and closing curly braces number');
        }
    }

    if (textRendered && textRendered.includes('data-limit-chars')) {

      // generation de DOM pour avoir accès aux sélecteurs (sélection par attribut 'data-limit-chars')
      const div = document.createElement('div');
      div.innerHTML = textRendered;
      const partsToTruncate = div.querySelectorAll('[data-limit-chars]');
      if (partsToTruncate && partsToTruncate.length) {
        let charsToKeep: number = -1, truncateNeed: boolean = false;
        for (let i = 0; i < partsToTruncate.length; i++) {

          charsToKeep = parseInt(partsToTruncate[i].getAttribute('data-limit-chars'));
          truncateNeed = (partsToTruncate[i].textContent) && (partsToTruncate[i].textContent.length > charsToKeep);

          if (charsToKeep && charsToKeep > 0 && truncateNeed) {

            // truncating logic applied here
            partsToTruncate[i].textContent = TemplateModelUtils.takeWordsOfNFirstCharacters(partsToTruncate[i].textContent, charsToKeep);
          }
        }

        // rendering text with truncations applied
        textRendered = div.innerHTML;
      }
    }

    return textRendered;

}

static sanitizeContentIdForMustache(id: string): string {
    return 'mdg_' + id.replace(/-/g, '_');
}

  static sanitizeContentIdForMustacheLink(id: string): string {
    return 'mdg_' + id.replace(/-/g, '_') + '_url';
  }

static compileContentUnlockedForEditorial(id: string): string {
  const variableName = TemplateModelUtils.sanitizeContentIdForMustache(id);
  return `{{#variables.${variableName}}}{{{variables.${variableName}}}}{{/variables.${variableName}}}`;
}

static renderContentUnlockedForEditorial(id: string, variables: object): string {
    const content = TemplateModelUtils.compileContentUnlockedForEditorial(id);
    return TemplateModelUtils.renderMustacheText(content, variables);
}

  static renderMustacheTemplate(template: string,
                                articles: Article[],
                                recos: RecoItem[],
                                variables: TemplateVariablesForMustache,
                                trackingParams: { [param: string]: string } = {},
                                rawTrackingParams: { [param: string]: string } = {},
                                anchorParam?: string): string {

    /*
     * SECTION: GENERATION OF MUSTACHE PARAMETERS
     * THIS SECTION MUST BE SYNCRHONIZED WITH THE CLASS com.mediego.template.Template ON THE SERVER SIDE
     */

    // Recommendation variables
    const formattedRecos: { [index: string]: RecoItem & { position: number } } = {};

    articles.forEach((article: Article, index: number) => {
      formattedRecos[`reco_${index}`] = {
        position: index + 1,
        ...recos[index]
      };
    });

    const mustacheParameters = {
      ...formattedRecos,
      ...TemplateModelUtils.getTemplateGlobalVariables(variables),
      ...TemplateModelUtils.getTemplateUserVariables()
    };

    /**
     * END SECTION
     */

    let preFinalHtml = template;
    try {
      preFinalHtml = Mustache.render(template, mustacheParameters);
    } catch (error) {
      console.error('MUSTACHE ERROR: mustache rendering failure, failsafe provided');
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(preFinalHtml, 'text/html');

    doc.querySelectorAll('[data-limit-words]')
      .forEach((el: Element) => {
        const limit = +el.getAttribute('data-limit-words')!;
        el.removeAttribute('data-limit-words'); // cleanup
        el.innerHTML = TemplateModelUtils.takeNFirstWords(el.innerHTML, limit);
      });

    doc.querySelectorAll('[data-limit-chars]')
      .forEach((el: Element) => {
        const limit = +el.getAttribute('data-limit-chars')!;
        el.removeAttribute('data-limit-chars'); // cleanup
        el.innerHTML = TemplateModelUtils.takeWordsOfNFirstCharacters(el.innerHTML, limit);
      });

    if (Object.entries(trackingParams).length) {
      doc.querySelectorAll('a')
        .forEach((el: HTMLAnchorElement) => {
          const trackingDisabled = el.getAttribute('data-disable-tracking') !== null;

          if (!trackingDisabled) {
            // Update href
            el.href = TemplateModelUtils.addTrackingParams(
              '' + el.href,
              trackingParams,
              rawTrackingParams,
              anchorParam
            );
          } else {
            el.removeAttribute('data-disable-tracking'); // cleanup
          }
        });
    }

    const serializer = new XMLSerializer();
    // noinspection UnnecessaryLocalVariableJS
    const finalHtml = serializer.serializeToString(doc);
    // if (finalHtml.startsWith('<!DOCTYPE html>')) { console.log(finalHtml); } // Helps to debug on EmailOnAcid
    return finalHtml;
  }

  static takeWordsOfNFirstCharacters(input: string, limit: number): string {
    let realLimit = limit;
    const trimmedInput = input.trim();

    if (limit > trimmedInput.length) {
      return trimmedInput;
    }

    // while not whitespace, go left
    while (!/\s/u.test(trimmedInput.charAt(realLimit))) {
      realLimit--;
    }

    return trimmedInput.substring(0, realLimit).trim().concat('...');
  }

  static takeNFirstWords(input: string, nbOfWords: number): string {
    const wordList = input.trim().split(' ');

    if (wordList.length > nbOfWords) {
      return wordList.slice(0, nbOfWords).join(' ').concat('...');
    } else {
      return wordList.join(' ');
    }
  }

  static addTrackingParams(linkWithoutTracking: string, trackingParams: { [param: string]: string }, rawTrackingParams: { [param: string]: string }, anchorParam?: string) {
    // Add protocol if necessary
    const linkWithProtocol = linkWithoutTracking.startsWith('http') ? linkWithoutTracking : 'http:' + linkWithoutTracking;

    // Add tracking params
    const urlBuilder = new URL(linkWithProtocol);

    Object
      .entries(trackingParams)
      .forEach(([key, value]) => {
        urlBuilder.searchParams.set(key, value);
      });

    // Remove existing anchor if another is provided in params
    if (anchorParam) {
      urlBuilder.hash = '';
    }

    let finalUrl: string = urlBuilder.href;

    // Add raw params
    if (Object.entries(rawTrackingParams).length) {
      const start = urlBuilder.search ? '&' : '?';
      const rawParamsString = Object
        .entries(rawTrackingParams)
        .map(([key, val]) => {
          return `${key}=${val}`;
        }).join('&');
      finalUrl += `${start}${rawParamsString}`;
    }

    // Add anchor
    if (anchorParam) {
      finalUrl += `#${anchorParam}`;
    }

    return finalUrl;
  }

}
